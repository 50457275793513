<template lang="">
  <ul v-if="pagination.total > conts.page_count && pagination.pageData" class="pagination" role="navigation"> 
    <li   class="page-item" style="cursor: pointer" v-bind:class="[ pagination.pageData.currentPage == 1 ?
       'disabled' : '', ]" @click="setPage(pagination.pageData.currentPage - 1)" rel="prev"
           aria-label="« 前へ" > <span class="page-link" aria-hidden="true">‹</span>
    </li>
    <li  
       style="cursor: pointer" class="page-item" aria-current="page"
       v-for="n in pagination.pageData.pages" :key="n.index"         
       v-bind:class="[ pagination.pageData.currentPage == n ? 'active' : '', ]" 
       @click="setPage(n)"> 
       <span class="page-link">{{ n }}</span> 
    </li>
    <li  style="cursor: pointer" class="page-item" aria-label="次へ »"
        @click="setPage(pagination.pageData.currentPage + 1)" v-bind:class="[ pagination.pageData.endPage ==
         pagination.pageData.currentPage ? 'disabled' : '', ]" > <span class="page-link"
        aria-hidden="true">›</span>
   </li> 
  </ul>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import CONST from "../../const/control_panel/common";

export default {
  data() {
    return {
      conts: CONST.PAGINATION,
      pageParam: {
        pageNo: 1,
        perPageCount: 5,
      },
    };
  },
  computed: {
    ...mapState("common", ["pagination", "paginationScreen"]),
  },
  watch: {
    pagination(val) {
      this.pagination.pageData = this.paginate(
        val.total,
        val.current_page,
        25,
        5
      );
    },
  },
  methods: {
    ...mapActions("news", ["getAllNews"]),

    ...mapActions("event", ["getAllEvent"]),

    ...mapActions("thanks", ["getAllThanks"]),

    ...mapActions("column", ["getAllColumn"]),

    ...mapActions("sdgs", ["getAllSdgs"]),

    ...mapActions("idea", ["getAllIdeas"]),

    ...mapActions("daily", ["getAllDaily"]),

    ...mapActions("user", ["getAllUsers"]),

    setPage(page) {
      this.pageParam.pageNo = page;

      switch (this.paginationScreen) {
        case "news": {
          this.getAllNews({
            papeParam: this.pageParam,
            type: this.paginationScreen,
          });

          break;
        }
        case "event": {
          this.getAllEvent({
            papeParam: this.pageParam,
            type: this.paginationScreen,
          });

          break;
        }
        case "thanks": {
          this.getAllThanks({
            pageParam: this.pageParam,
            type: this.paginationScreen,
          });

          break;
        }
        case "thanks-summary": {
          this.getAllThanks({
            pageParam: this.pageParam,
            type: this.paginationScreen,
          });

          break;
        }
        case "column": {
          this.getAllColumn({
            pageParam: this.pageParam,
            type: this.paginationScreen,
          });

          break;
        }
        case "sdgs": {
          this.getAllSdgs({
            pageParam: this.pageParam,
            type: this.paginationScreen,
          });

          break;
        }
        case "idea": {
          this.getAllIdeas({
            pageParam: this.pageParam,
            type: this.paginationScreen,
          });

          break;
        }
        case "daily": {
          this.getAllDaily({
            pageParam: this.pageParam,
            type: this.paginationScreen,
          });

          break;
        }
        case "user": {
          this.getAllUsers({
            pageParam: this.pageParam,
          });

          break;
        }

        default:
          break;
      }
    },
    paginate(totalItems = null, currentPage = 1, pageSize = 25, maxPages = 5) {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);

      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }

      let startPage, endPage;

      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize, totalItems);
      // let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
      );

      // return object with all pager properties required by the view
      return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages,
      };
    },
  },
  mounted() {
    console.log(
      this.paginate(this.pagination.total, this.pagination.current_page, 5, 5)
    );
    this.pagination.pageData = this.paginate(
      this.pagination.total,
      this.pagination.current_page,
      25,
      5
    );
  },
};
</script>
<style lang=""></style>
