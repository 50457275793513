export default {

    LIST: {

        title: "イベント",

        sub_title: "イベント一覧",

        create: "新規投稿",

        breadcrumb: {

            dashboard: "ダッシュボード ",

            event: "イベント"

        },
        grid: {

            title: "タイトル",

            posted_date: "投稿日",
            address: "宛先",

            updated_by: "編集者",

            created_date: "投稿日",

            published_date: "公開日",

            status: "申請状況",

            venue: "開催場所",

            number_of_persons: "最大参加人数",

            start_date: "開始日時",

            end_date: "終了日時",

            contact_info: "問い合わせ先"

        },
        button: {

            create: "新規投稿",

            update: "編集",

            show: "表示",

            delete: "アーカイブ",

            search: "検索"
        },
        status: {

            release: "公開",

            draft: "下書き",

            archive: "アーカイブ",

            un_select: "未選択",

            select_all: "すべて",

            has_been_selected: "が選択済みです"
        },
        search: {

            user: "ユーザー",

            category: "カテゴリ",

            status: "ステータス",

            release_date: "公開日",

            published_start: "開始日",

            published_date: "公開日",

            published_end: "終了日",

            search_results: "検索結果：",

            no_results: "検索結果はありません"

        },
        delete: {

            title: "確認",

            description: "アーカイブに移動しますか？",

            execution: "実行",

            cancel: "キャンセル"
        },
        pagination_url: "event",
        
        search_validation: {

            start_date_format: "開始日付には年月日を入力ください。（例：2021/10/28）。",

            end_date_format: "終了日付には年月日を入力ください。（例：2021/10/28）。",

            start_date_limit: "開始日には終了日以前の日付をご利用ください。",

            end_date_limit: "終了日には開始日以降の日付をご利用ください。",
        }
    },

}