<template>
  <div
    class="modal"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal_inner confirm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form_outwrap">
              <div class="modal_header">
                <p class="ttl_header">{{ title }}</p>
              </div>
              <div class="modal_wrap">
                <div class="for_wrap h_80">
                  <div class="modal_contents">
                    <p>
                      <span>{{ message }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal_footer">
                <div class="flex_wrap f_c">
                  <div class="btn_norm reverse" id="cancel_bk">
                    <button
                      name=""
                      value="キャンセル"
                      class="close"
                      dusk="delete_data"
                      @click="closeDiscard()"
                    >
                      キャンセル
                    </button>
                  </div>
                  <div class="btn_norm delete">
                    <button
                      @click="deleteConfirm()"
                      class="btn btn"
                      value="アーカイブ"
                      title="削除"
                      dusk="delete_data"
                    >
                      実行
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        id="close_btn"
        class="pop-remove_btn ttoltip tip-top close"
        @click="closeDiscard()"
      >
        <i class="fas fa-times"></i>
      </p>
      <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
    </div>
    <!--modal_inner-->
  </div>
  <!--modal-->
</template>

<script>
import $ from "jquery";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "Delete",
  props: ["message", "title", "model"],
  computed: {
    ...mapState("news", ["deleteId"]),
    ...mapState("event", ["deleteId"]),
    ...mapState("thanks", ["thanksDeleteId"]),
    ...mapState("column", ["columnDeleteId"]),
    ...mapState("sdgs", ["sdgsDeleteId"]),
    ...mapState("daily", ["dailyDeleteId"]),
    ...mapState("idea", ["ideaDeleteId"]),
    ...mapState("user", ["userDeleteId"]),
  },
  created() {},
  methods: {
    ...mapActions("news", ["deleteNews"]),
    ...mapActions("event", ["deleteEvent"]),
    ...mapActions("thanks", ["deleteThanks"]),
    ...mapActions("column", ["deleteColumn"]),
    ...mapActions("sdgs", ["deleteSdgs"]),
    ...mapActions("daily", ["deleteDaily"]),
    ...mapActions("idea", ["deleteIdeas"]),
    ...mapActions("user", ["deleteUser"]),
    ...mapActions("common", ["setSpinnerStatus", "setSucccessMessageStatus"]),
    /**
     * single delete news
     * @author Elavarasan
     * @date 23/10/2021
     */
    deleteConfirm() {
      switch (this.model) {
        case "news":
          {
            this.deleteNews(this.deleteId);
          }
          break;
        case "event":
          {
            this.deleteEvent(this.deleteId);
          }
          break;
        case "thanks":
          {
            this.deleteThanks(this.thanksDeleteId);
          }
          break;
        case "column":
          {
            this.deleteColumn(this.columnDeleteId);
          }
          break;
        case "sdgs":
          {
            this.deleteSdgs(this.sdgsDeleteId);
          }
          break;
        case "daily":
          {
            this.deleteDaily(this.dailyDeleteId);
          }
          break;
        case "idea":
          {
            this.deleteIdeas(this.ideaDeleteId);
          }
          break;
        case "user":
          {
            this.deleteUser(this.userDeleteId); // like this
          }
          break;
        default:
          break;
      }
    },
    /**
     * discurd close option
     * @author Elavarasan
     *  @date 23/10/2021
     */
    closeDiscard() {
      $("#confirmModal").removeClass("open");
    },
  },
};
</script>
